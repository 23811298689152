import { ArContactForm } from 'src/components/ArContactForm'
import { ArContainer } from 'src/components/ArContainer'
import { ArSeo } from 'src/components/ArSeo'
import { ROUTES } from 'src/config/routes'
import React from 'react'

const ContactPage = () => {
  return (
    <>
      <ArSeo route={ROUTES.contact()} />
      <ArContainer>
        <ArContactForm />
      </ArContainer>
    </>
  )
}

export default ContactPage
