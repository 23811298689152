import * as s from './ArTextarea.module.scss'

import React from 'react'
import { TArTextareaProps } from './ArTextarea.types'
import classNames from 'classnames'

export const ArTextarea = React.forwardRef<HTMLDivElement, TArTextareaProps>(
  ({ className, style, label, name, error, ...rest }, ref) => {
    let errorMessage = error?.message

    if (error?.type === 'required') {
      errorMessage = 'This field is required'
    }

    const containerClass = classNames(s.container, className, {
      [s.error]: errorMessage,
    })

    return (
      <div ref={ref} className={containerClass} style={style}>
        <label htmlFor={'textarea_' + name}>{label}</label>
        <div className={classNames(s.inputWrapper, { [s.hasValue]: !!rest.value })}>
          <textarea id={'textarea_' + name} name={name} title={name} {...rest} />
        </div>
        {errorMessage && <span className={s.error}>{errorMessage}</span>}
      </div>
    )
  },
)
